<template>
  <NuxtLink
    class="text-center"
    :to="link"
    :external="downloadLinkActive ? true : false"
  >
    <div class="rounded-md overflow-hidden">
      <NuxtImg
        v-if="magazine.ImageUrl"
        :src="magazine.ImageUrl"
        :width="370"
        :height="500"
        class="max-w-56 md:max-w-64"
      />
    </div>
    <div class="mt-3" :class="isMm ? 'label-m' : 'list-title-s !font-medium'">
      {{ magazine.Name }}
    </div>
  </NuxtLink>
</template>

<script setup lang="ts">
import type { Magazine } from '~/api/content'

const { isMm } = useBrand()

const userStore = useUserStore()

const props = defineProps<{ magazine: Magazine }>()

const downloadLinkActive = computed(() => {
  return userStore.isLoggedIn
})

const link = computed(() => {
  if (isMm) {
    if (downloadLinkActive.value) {
      return `${props.magazine.Link}?token=${encodeURI(
        userStore.user?.legacyAutoToken ?? ''
      )}`
    } else {
      return { name: 'login' }
    }
  } else {
    return {
      name: 'magasin_vis',
      params: {
        id: props.magazine.RecordId,
      },
    }
  }
})
</script>
